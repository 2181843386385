<template>
    <div style="width: 100%;height: 100%;">
        <v-chart ref="lineChart" :option="option" :autoresize="true" style="width: 100%; height: 100%"></v-chart>
    </div>
</template>

<script>
import 'echarts/lib/chart/line'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/theme/macarons'
export default {
    data() {
        return {
            option: {}
        };
    },
    props: {
        opt: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        opt: {
            handler(newData) {
                console.log('新数据', newData.title)
                this.option = {
                    textStyle: {
                        color: '#fff'
                    },
                    legend: {
                        textStyle: {
                            color: '#fff' // 标题颜色
                        },
                        right: 'top',
                    },
                    title: {
                        text: newData.title,
                        textStyle: {
                            color: '#fff' // 标题颜色
                        }
                    },
                    tooltip: {
                        trigger: "axis",
                        textStyle: {
                            color: "#fff" //设置文字颜色
                        },
                        backgroundColor: "rgba(0,0,0,0.6)",
                        axisPointer: {
                            type: "shadow",

                            label: {
                                show: true,
                                backgroundColor: "#7B7DDC"
                            }
                        }
                    },
                    grid: {
                        left: '2%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },

                    xAxis: {
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: "#B4B4B4"
                            }
                        },
                        axisLabel: {
                            rotate: 45 // 将 x 轴标签文本旋转 45 度
                        },
                        axisTick: {
                            show: false
                        },
                        boundaryGap: false,
                        data: newData.xAxis
                    },
                    yAxis: [
                        {
                            splitLine: { show: false },
                            axisTick: {
                                length: 0
                            },

                            axisLine: {
                                lineStyle: {
                                    color: "#B4B4B4"
                                }
                            },
                            axisLabel: {
                                formatter: newData.unit ? "{value}" + newData.unit : "{value}",
                                margin: 20 // 将标签距离坐标轴设置为20像素
                            }
                        },

                    ],
                    series: newData.series
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {

    },
    created() {

    },
    mounted() {

    },
};
</script>

<style scoped lang="scss"></style>
