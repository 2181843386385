<template>
  <div class="main">
    <div class="bg">
      <div v-if="loading" style="width: 100%; height: 100%">
        <dv-loading v-if="loading">Loading...</dv-loading>
      </div>
      <div v-else class="container">
        <div class="info">
          <div class="col-3 item-left">
            <dv-border-box-1>
              <div class="state padding-20">
                <p class="title-2">监测任务状态</p>
                <div style="padding-left: 5px; padding-top: 5px">
                  <p>任务起始：{{ resTime.startTime }}</p>
                  <p v-if="info.state.pkTaskRunningStateId !== 3">当前时间：{{ newTime }}</p>
                  <p v-else>结束时间：{{ resTime.endTime }}</p>
                  <p>任务状态：{{ info.state.runningState }}</p>
                  <p>累计时长：{{ resTime.time + 'h' }}</p>
                  <p>经纬度：{{ info.longitude + '/' + info.latitude }}</p>
                  <p>天气情况：{{ weather }}</p>
                </div>
              </div>
            </dv-border-box-1>
          </div>
          <div class="col-12 item-center">
            <dv-border-box-11 :title="info.projectName" :titleWidth="info.projectName.length * 18 + 30">
              <div class="center-box padding-top-55 padding-20">
                <div class="col-5">
                  <div class="item c1">
                    <p class="p-t">沉降监测点数量</p>
                    <p class="f_24">{{ total.V }}</p>
                  </div>
                  <div class="item c2">
                    <p class="p-t">轴力监测点数量</p>
                    <p class="f_24">{{ total.F }}</p>
                  </div>
                </div>
                <div class="col-7">
                  <div class="safe-box">
                    <dv-border-box-10>
                      <div class="item safe c5">
                        <p class="p-t">监测结果</p>
                        <p class="f_32" style="color: greenyellow" v-if="info.fkSafeStateId === 1">监测数据正常</p>
                        <p class="f_32" style="color: #ff7f27" v-if="info.fkSafeStateId === 2">数据超限预警</p>
                        <p class="f_32" style="color: #f20f18" v-if="info.fkSafeStateId === 3">数据超限报警</p>
                      </div>
                    </dv-border-box-10>
                  </div>
                </div>
                <div class="col-5">
                  <div class="item c3">
                    <p class="p-t">位移监测点数量</p>
                    <p class="f_24">{{ total.H }}</p>
                  </div>
                  <div class="item c4">
                    <p class="p-t">倾角监测点数量</p>
                    <p class="f_24">{{ total.L }}</p>
                  </div>
                </div>
              </div>
            </dv-border-box-11>
          </div>
          <div class="col-3 item-right">
            <dv-border-box-1>
              <div class="state padding-20">
                <p class="title-2">监测任务信息</p>
                <div style="padding-left: 5px; padding-top: 5px">
                  <p>任务编号：{{ info.projectNo }}</p>
                  <!-- <p>监测单位：{{ info.company.companyName }}</p> -->
                  <p>任务地址：{{ info.projectAddress }}</p>
                  <p v-show="phoneList.length > 0 && index < 3" v-for="(item, index) in phoneList" :key="item.id">监测员{{ index + 1 }}:{{ item.name + '--' + item.phone }}</p>
                  <p v-show="phoneList.length < 0">监测员:无</p>
                </div>
              </div>
            </dv-border-box-1>
          </div>
        </div>

        <div class="chart">
          <div class="col-9">
            <dv-border-box-1>
              <div style="width: calc(100% - 40px); height: calc(100% - 40px)" class="padding-20">
                <LineChart :opt="optV" />
              </div>
            </dv-border-box-1>
          </div>
          <div class="col-9">
            <dv-border-box-8>
              <div style="width: calc(100% - 40px); height: calc(100% - 40px)" class="padding-20">
                <LineChart :opt="optF" />
              </div>
            </dv-border-box-8>
          </div>

          <div class="col-9">
            <dv-border-box-6>
              <div style="width: calc(100% - 40px); height: calc(100% - 40px); display: flex" class="padding-20">
                <div style="flex: 1">
                  <LineChart :opt="optHX" />
                </div>
                <div style="flex: 1">
                  <LineChart :opt="optHY" />
                </div>
              </div>
            </dv-border-box-6>
          </div>
          <div class="col-9">
            <dv-border-box-12>
              <div style="width: calc(100% - 40px); height: calc(100% - 40px); display: flex" class="padding-20">
                <div style="flex: 1">
                  <LineChart :opt="optLX" />
                </div>
                <div style="flex: 1">
                  <LineChart :opt="optLY" />
                </div>
              </div>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './LineChart.vue'
import { findTaskNewData, findTaskByTaskId, findTaskPhone, findTime } from '@/api/Task.js'
export default {
  data() {
    return {
      optV: {},
      optF: {},
      optHX: {},
      optHY: {},
      optLX: {},
      optLY: {},
      loading: true,
      alarmColor: '#FF0000',
      warningColor: '#FFA500',
      valueColor: '#5470C6',

      c1: '#F8A1A4',
      c2: '#13D0B2',
      c3: '#F6A744',
      c4: '#C785C8',
      total: { V: 0, F: 0, L: 0, H: 0 },
      info: {},
      phoneList: [],
      weather: '请填写项目经纬度',
      newTime: '',
      timer1: null,
      timer2: null,
      timer3: null,
      resTime: {},
    }
  },
  components: { LineChart },

  methods: {
    init() {
      this.findTaskByTaskIdFunc().then(() => {
        if (!this.info.longitude || !this.info.latitude) {
          return
        }
        this.getWeather()
      })
      this.findAllList()
      this.displayCurrentTime()

      setTimeout(() => {
        this.loading = false
      }, 1000)

      this.timer1 = setInterval(() => {
        this.displayCurrentTime()
      }, 1000)

      this.timer2 = setInterval(() => {
        this.findTaskByTaskIdFunc()
        this.findAllList()
      }, 1000 * 60 * 5)

      this.timer3 = setInterval(() => {
        this.getWeather()
      }, 1000 * 60 * 30)
    },

    getWeather() {
      fetch(`https://devapi.qweather.com/v7/weather/now?location=${this.info.longitude + ',' + this.info.latitude}&key=639942329b294e8289cd25261232dc1f`)
        .then((response) => response.json())
        .then((data) => {
          this.weather = data.now.text + '/' + data.now.temp + '℃/' + data.now.humidity + '%'
        })
        .catch(() => {
          this.weather = '请填写项目经纬度'
        })
    },
    // 获取当前时间
    displayCurrentTime() {
      const now = new Date()
      const year = now.getFullYear()
      const month = ('0' + (now.getMonth() + 1)).slice(-2)
      const date = ('0' + now.getDate()).slice(-2)
      const hours = ('0' + now.getHours()).slice(-2)
      const minutes = ('0' + now.getMinutes()).slice(-2)
      const second = ('0' + now.getSeconds()).slice(-2)
      const currentTime = `${year}-${month}-${date} ${hours}:${minutes}:${second}`
      this.newTime = currentTime
    },
    // 查询任务信息
    async findTaskByTaskIdFunc() {
      const { data: res } = await findTaskByTaskId(this.$route.params.projectId)
      const { data: phoneList } = await findTaskPhone(this.$route.params.projectId)
      const { data: resTime } = await findTime(this.$route.params.projectId)
      this.resTime = resTime.data
      this.phoneList = phoneList.data
      this.info = res.data.list[0]
    },
    // 查询监测点信息
    async findAllList() {
      const { data: listV } = await findTaskNewData('V', this.$route.params.projectId)
      const { data: listF } = await findTaskNewData('F', this.$route.params.projectId)
      const { data: listH } = await findTaskNewData('H', this.$route.params.projectId)
      const { data: listL } = await findTaskNewData('L', this.$route.params.projectId)
      this.handleVList(listV)
      this.handleFList(listF)
      this.handleHList(listH)
      this.handleLList(listL)
      this.total.V = listV.data ? listV.data.length : 0
      this.total.F = listF.data ? listF.data.length : 0
      this.total.H = listH.data ? listH.data.length : 0
      this.total.L = listL.data ? listL.data.length : 0
    },
    getAlarmLine(dataList, warningValue, alarmValue, sign = true) {
      if (sign) {
        return [
          {
            name: '最新值',
            type: 'bar',
            label: {
              show: true,
              color: '#fff',
            },
            data: dataList,
            barMaxWidth: 30,
            lineStyle: { color: this.valueColor },

            itemStyle: {
              normal: {
                barBorderRadius: 3,
                color: (params) => {
                  if (Math.abs(params.value) > Math.abs(alarmValue[params.dataIndex])) {
                    return this.alarmColor
                  } else if (Math.abs(params.value) > Math.abs(warningValue[params.dataIndex])) {
                    return this.warningColor
                  } else {
                    return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#5470C6' },
                      { offset: 1, color: '#8097DB' },
                    ])
                  }
                },
              },
            },
            z: 3,
          },
          {
            name: '预警值',
            type: 'line',
            data: warningValue,
            lineStyle: {
              color: this.warningColor,
              type: 'dashed',
              width: 1,
            },
            itemStyle: { color: this.warningColor },
            z: 2,
          },
          {
            name: '预警值',
            type: 'line',
            data: warningValue.map((item) => -item),
            lineStyle: {
              color: this.warningColor,
              type: 'dashed',
              width: 1,
            },
            itemStyle: { color: this.warningColor },
            z: 2,
          },
          {
            name: '报警值',
            type: 'line',
            data: alarmValue,
            lineStyle: {
              color: this.alarmColor,
              type: 'dashed',
              width: 1,
            },
            itemStyle: { color: this.alarmColor },
            z: 1,
          },
          {
            name: '报警值',
            type: 'line',
            data: alarmValue.map((item) => -item),
            lineStyle: {
              color: this.alarmColor,
              type: 'dashed',
              width: 1,
            },
            itemStyle: { color: this.alarmColor },

            z: 1,
          },
        ]
      } else {
        return [
          {
            name: '最新值',
            type: 'bar',
            label: {
              show: true,
              color: '#fff',
            },
            barMaxWidth: 30,
            data: dataList,
            lineStyle: { color: this.valueColor },
            itemStyle: {
              normal: {
                barBorderRadius: 3,
                color: (params) => {
                  if (params.value > alarmValue[params.dataIndex]) {
                    return this.alarmColor
                  } else if (params.value > warningValue[params.dataIndex]) {
                    return this.warningColor
                  } else {
                    return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#5470C6' },
                      { offset: 1, color: '#8097DB' },
                    ])
                  }
                },
              },
            },
            z: 3,
          },
          {
            name: '预警值',
            type: 'line',
            data: warningValue,
            lineStyle: {
              color: this.warningColor,
              type: 'dashed',
              width: 1,
            },
            itemStyle: { color: this.warningColor },
            z: 2,
          },
          {
            name: '报警值',
            type: 'line',
            data: alarmValue,
            lineStyle: {
              color: this.alarmColor,
              type: 'dashed',
              width: 1,
            },
            itemStyle: { color: this.alarmColor },
            z: 2,
          },
        ]
      }
    },

    handleVList(list) {
      const listNew = list.data ? list.data.filter((item) => item !== null) : []
      const dataList = listNew.map((item) => {
        return item.displacementChange ? item.displacementChange : 0
      })
      const warningValue = listNew.map((item) => {
        return item.point.warningValue
      })
      const alarmValue = listNew.map((item) => {
        return item.point.alarmValue
      })
      this.$set(this.optV, 'title', '竖向位移')
      this.$set(this.optV, 'unit', 'mm')
      this.$set(
        this.optV,
        'xAxis',
        listNew.map((item) => {
          return item.point.pointName
        })
      )
      this.$set(this.optV, 'series', this.getAlarmLine(dataList, warningValue, alarmValue))
    },
    handleFList(list) {
      const listNew = list.data ? list.data.filter((item) => item !== null) : []
      const dataList = listNew.map((item) => {
        return item.axialForceChange
      })
      const warningValue = listNew.map((item) => {
        return item.point.warningValue
      })
      const alarmValue = listNew.map((item) => {
        return item.point.alarmValue
      })
      this.$set(this.optF, 'title', '立杆轴力')
      this.$set(this.optF, 'unit', 'N')
      this.$set(
        this.optF,
        'xAxis',
        listNew.map((item) => {
          return item.point.pointName
        })
      )
      this.$set(this.optF, 'series', this.getAlarmLine(dataList, warningValue, alarmValue, false))
    },
    handleHList(list) {
      const listNew = list.data ? list.data.filter((item) => item !== null) : []

      const dataListX = listNew.map((item) => {
        return item.xDisplacementAccumulateChange
      })
      const dataListY = listNew.map((item) => {
        return item.yDisplacementAccumulateChange
      })
      const warningValue = listNew.map((item) => {
        return item.point.warningValue
      })

      const alarmValue = listNew.map((item) => {
        return item.point.alarmValue
      })

      this.$set(this.optHX, 'title', 'X位移')
      this.$set(this.optHX, 'unit', 'mm')
      this.$set(
        this.optHX,
        'xAxis',
        listNew.map((item) => item.point.pointName)
      )
      this.$set(this.optHX, 'series', this.getAlarmLine(dataListX, warningValue, alarmValue))

      this.$set(this.optHY, 'title', 'Y位移')
      this.$set(this.optHY, 'unit', 'mm')
      this.$set(
        this.optHY,
        'xAxis',
        listNew.map((item) => item.point.pointName)
      )
      this.$set(this.optHY, 'series', this.getAlarmLine(dataListY, warningValue, alarmValue))
    },
    handleLList(list) {
      const listNew = list.data ? list.data.filter((item) => item !== null) : []

      const dataListX = listNew.map((item) => {
        return item.xAngleAccumulateChange
      })

      const dataListY = listNew.map((item) => {
        return item.yAngleAccumulateChange
      })
      const warningValue = listNew.map((item) => {
        return item.point.warningValue
      })

      const alarmValue = listNew.map((item) => {
        return item.point.alarmValue
      })

      this.$set(this.optLX, 'title', 'X倾角')
      this.$set(
        this.optLX,
        'xAxis',
        listNew.map((item) => {
          return item.point.pointName
        })
      )
      this.$set(this.optLX, 'series', this.getAlarmLine(dataListX, warningValue, alarmValue))

      this.$set(this.optLY, 'title', 'Y倾角')
      this.$set(
        this.optLY,
        'xAxis',
        listNew.map((item) => {
          return item.point.pointName
        })
      )
      this.$set(this.optLY, 'series', this.getAlarmLine(dataListY, warningValue, alarmValue))
    },
  },
  created() {},
  mounted() {
    this.init()
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: hidden;
  color: #fff;

  .bg {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/image/pageBg.png');
    background-size: cover;
    background-position: center center;
  }

  .container {
    height: 92vh;
    padding: 4vh 50px;
    display: flex;
    flex-direction: column;
  }

  .info {
    display: flex;
    height: 240px;

    .item-left,
    .item-right {
      margin-top: 20px;
      height: 220px;

      .state {
        line-height: 24px;
        position: relative;
      }

      p.title-2 {
        position: absolute;
        left: 50%;

        transform: translate(-50%, 0%);
        top: -10px;
      }

      ::v-deep .unit-label {
        opacity: 0;
      }
    }

    .center-box {
      display: flex;

      .safe-box {
        height: 143px;
      }

      .item {
        margin: 15px;
        height: 64px;
        border-radius: 5px;

        p {
          line-height: 30px;
          // font-size: 16px;
          font-weight: 700;
          text-align: center;
        }

        .f_24 {
          font-size: 24px;
        }

        .f_32 {
          font-size: 32px;
        }
        .p-t {
          font-weight: 500;
        }
      }

      .safe {
        height: 120px;

        p {
          line-height: 60px;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .p-t {
          font-weight: 500;
        }
      }
    }
  }

  .chart {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
  }
}

.col-3 {
  flex: 4;
}

.col-6 {
  flex: 1;
}

.col-5 {
  flex: 5;
}

.col-7 {
  flex: 7;
}

.col-12 {
  flex: 10;
}

.col-9 {
  flex-basis: 50%;
  height: 50%;
  overflow: hidden;
}

.padding-20 {
  padding: 20px;
}

.padding-top-55 {
  padding-top: 55px;
}

.title-2 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
}

.c3 {
  background-color: #ff9671;
}

.c4 {
  background-color: #5470c6;
}

.c1 {
  background-color: #ff6f91;
}

.c2 {
  background-color: #00c9a7;
}
</style>
